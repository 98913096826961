import { IDataProvider } from "./DataProvider";
import { IStory } from "../Types";

const characters = require('../data/characters.json');
const conflicts = require('../data/conflicts.json');
const scenarios = require('../data/scenarios.json');
const genres = require('../data/genres.json');

class DataLocalProvider implements IDataProvider {
  static STORIES_KEY: 'STORIES';

  randomInArray(items: any[]): any {
    return items[Math.floor(Math.random() * items.length)];
  }

  async getRandomStory(): Promise<IStory> {
    return {
      character: this.randomInArray(characters),
      conflict: this.randomInArray(conflicts),
      scenario: this.randomInArray(scenarios),
      genre: this.randomInArray(genres),
    };
  }
}

export default DataLocalProvider;
