import { local } from 'store2';
import Preferences from './Preferences';

export default class Config {
    private static LOCALE_KEY = 'locale';

    static useLocale(langCode: string): void {
        local.set(Config.LOCALE_KEY, langCode, true);
    }

    static getCurrentLocale(): string {
        return local.get(Config.LOCALE_KEY, Preferences.getDefaultLocale());
    }
}
