import { get as getProp } from 'dot-prop';
import Config from './Config';
import Preferences from './Preferences';
const strings = require('../data/strings.json');
const stringsES = require('../data/strings_ES.json');

export default class Resource {
    static getStrings(id?: string): any {
        switch (id || Config.getCurrentLocale()) {
            case 'ES':
                return stringsES;
            default:
                return strings;
        }
    }

    static getDefaultStrings(): any {
        return Resource.getStrings(Preferences.getDefaultLocale());
    }

    static msg(path: string, defaultValue?: string): string {
        let value: string = getProp(Resource.getStrings(), path) || '';

        // Fallback to default strings
        if (!value) {
            value = getProp(Resource.getDefaultStrings(), path, defaultValue ? defaultValue : '');
        }

        return value;
    }

    /**
     * Gets a message from an object and tries to find for the
     * translation using key_Locale notation based on the current
     * app locale
     * @param obj The object in which to search
     * @param key The key to search for (supports dot notation)
     */
    static msgL(obj: any, key: string): string {
        if (!obj || !key) return '';

        let msg: string = '';

        if (Config.getCurrentLocale() !== Preferences.getDefaultLocale()) {
            msg = getProp(obj, `${key}_${Config.getCurrentLocale()}`, '');
        }

        if (!msg) {
            msg = getProp(obj, key, '');
        }

        return msg;
    }
}
