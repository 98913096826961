import Preferences from "./Preferences";
import Resource from "./Resource";

export default class Marketing {
  static goToBlogPost(): void {
    const urls = Preferences.get('urls');
    const url = Resource.msgL(urls, 'blogpost');
    window.open(url, '_blank');
  }

  static goToLandingPage(): void {
    const urls = Preferences.get('urls');
    const url = Resource.msgL(urls, 'landing');
    window.open(url, '_blank');
  }
}
