import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import React, { useEffect } from 'react';
import Data from '../scripts/Data';

const Resources: React.FC = () => {

  useEffect(() => {
    Data.getInstance().analyticsProvider.setCurrentScreen('Resources Page');
  }, []);

  return (
    <IonPage>
      <IonHeader className="header-no-shadow">
        <IonToolbar>
          <IonTitle>Resources</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        The world is your oyster.
        <p>
          If you get lost, the{' '}
          <a target="_blank" rel="noopener noreferrer" href="https://ionicframework.com/docs/">
            docs
          </a>{' '}
          will be your guide.
        </p>
      </IonContent>
    </IonPage>
  );
};

export default Resources;
