import React from 'react';
import { IonTabs, IonRouterOutlet, IonTabBar, IonTabButton, IonLabel } from '@ionic/react';
import Home from './pages/Home';
import { Route, Redirect } from 'react-router';
import About from './pages/About';
import Resources from './pages/Resources';
import Story from './pages/Story';
import Config from './pages/Config';
import Preferences from './scripts/Preferences';
import Resource from './scripts/Resource';

const TabsNavigation: React.FC = () => (
    <IonTabs>
        <IonRouterOutlet>
            <Route path="/generator" component={Home} exact={true} />
            <Route path="/resources" component={Resources} exact={true} />
            <Route path="/about" component={About} exact={true} />
            <Route path="/story" component={Story} exact={true} />
            <Route path="/config" component={Config} exact={true} />
            <Route exact path="/" render={() => <Redirect to="/generator" />} />
        </IonRouterOutlet>

        <IonTabBar slot="bottom">
            <IonTabButton tab="generator" href="/generator">
                <IonLabel>{Resource.msg('generator.tabTitle')}</IonLabel>
            </IonTabButton>
            {Preferences.get('app.enableResources') && <IonTabButton tab="resources" href="/resources">
                <IonLabel>Resources</IonLabel>
            </IonTabButton>}
            <IonTabButton tab="config" href="/config">
                <IonLabel>{Resource.msg('config.title')}</IonLabel>
            </IonTabButton>
        </IonTabBar>
    </IonTabs>
);

export default TabsNavigation;
