import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Plugins } from '@capacitor/core';
import Config from './scripts/Config';
import Data from './scripts/Data';
import Preferences from './scripts/Preferences';

// Hide splashscreen
const { SplashScreen } = Plugins;
SplashScreen.hide();

// Use a locale if defined in the ?lang search param
const urlParams = new URLSearchParams(window.location.search);
if (urlParams.get('lang')) {
  Config.useLocale(urlParams.get('lang') as string);
}

// Track locale
Data.getInstance().analyticsProvider.trackUserProperties({ locale: Config.getCurrentLocale() });

// Track Pro usage
Data.getInstance().analyticsProvider.track('app-open', {
  isPro: Preferences.isProVersion()
});

if (Preferences.isProVersion()) {
  Data.getUserId().then((id) => {
    Data.getInstance().analyticsProvider.setUserId(id);
  })
}

// Render the app
ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
