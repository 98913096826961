import { local } from 'store2';
// @ts-ignore
import * as uuid from 'uuid/v4';
import { IDataProvider } from "./DataProvider";
import Preferences from "./Preferences";
import DataFirebaseProvider from "./DataFirebaseProvider";
import DataLocalProvider from "./DataLocalProvider";
import { IAnalyticsProvider } from './AnalyticsProvider';

export default class Data {
  static STORIES_KEY = 'STORIES';
  static USER_ID_KEY = 'userId';
  static instance: Data;

  dataStrategy: string;
  dataProvider: IDataProvider;
  analyticsProvider: IAnalyticsProvider;

  constructor(dataStrategy?: string) {
    this.dataStrategy = dataStrategy || Preferences.get('dataStrategy');

    switch (this.dataStrategy) {
      case 'firebase':
        this.dataProvider = new DataFirebaseProvider();
        break;
      default:
        this.dataProvider = new DataLocalProvider();
    }

    this.analyticsProvider = new DataFirebaseProvider();
  }

  /**
   * Creates a singleton of this class
   */
  static getInstance() {
    if (!Data.instance) {
      Data.instance = new Data();
    }

    return Data.instance;
  }

  /**
   * Saves a story to the local storage
   * @param story The story to save
   */
  static async saveStory(story: any) {
    let stories: any[] = await Data.getStories();
    stories = stories.filter(st => st.id !== story.id);

    const now = Date.now();
    if (!story.createdAt) {
      story.createdAt = now;
    }

    story.updatedAt = now;
    stories.push(story);

    local.set(Data.STORIES_KEY, stories, true);
  }

  static async getStory(id: string) {
    const stories = await Data.getStories();
    return stories.filter(story => story.id === id)[0];
  }

  /**
   * Gets the user saved stories
   */
  static async getStories(): Promise<any[]> {
    return local.get(Data.STORIES_KEY) || [];
  }

  /**
   * Gets the user id or creates a new one
   */
  static async getUserId(): Promise<string> {
    if (!local.get(Data.USER_ID_KEY)) {
      local.set(Data.USER_ID_KEY, uuid());
    }

    return local.get(Data.USER_ID_KEY);
  }
}
