import { get as getProp } from 'dot-prop';
const preferences = require('../data/preferences.json');

export interface ILocale {
    id: string,
    name: string
}

export default class Preferences {
    static isProVersion(): boolean {
        return getProp(preferences, 'app.isProVersion', false);
    }

    static get(path: string, defaultValue?: string): string {
        return getProp(preferences, path, defaultValue ? defaultValue : '');
    }

    static getDefaultLocale(): string {
        return preferences.defaultLocale;
    }

    static getLocales(): ILocale[] {
        return Object.keys(preferences.locales).map((localeId) => ({
            id: localeId, name: preferences.locales[localeId]
        }));
    }
}
