import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/analytics';

import * as firebase from "firebase/app";
import { get } from 'dot-prop';
import { title } from 'case';
import { IDataProvider } from "./DataProvider";
import { IStory, ITranslatableItem } from "../Types";
import { IAnalyticsProvider } from "./AnalyticsProvider";

var firebaseConfig = {
  apiKey: "AIzaSyDi0l9oL41v-qnzjqA9PcKT9630z8_wfHA",
  authDomain: "owsas-story-generator.firebaseapp.com",
  databaseURL: "https://owsas-story-generator.firebaseio.com",
  projectId: "owsas-story-generator",
  storageBucket: "owsas-story-generator.appspot.com",
  messagingSenderId: "769162470444",
  appId: "1:769162470444:web:10eb10a4c22000aa811d89",
  measurementId: "G-C4CF7F69GM"
};

const app = firebase.initializeApp(firebaseConfig);

export default class DataFirebaseProvider implements IDataProvider, IAnalyticsProvider {
  static getFirebaseApp() {
    return app;
  }

  static getFirestore() {
    return app.firestore();
  }
  
  /**
   * Gets a random story from firebase
   */
  async getRandomStory(): Promise<IStory> {
    const randomStory = DataFirebaseProvider
      .getFirebaseApp()
      .functions('us-central1')
      .httpsCallable('randomStory');

    const story = await randomStory();
    const storyData = story.data;

    const character: ITranslatableItem = { name: title(get(storyData, 'character.id', '')) };
    const scenario: ITranslatableItem = { name: title(get(storyData, 'scenario.id', '')) };
    const conflict: ITranslatableItem = {
      name: title(get(storyData, 'conflict.id', '')),
      description: title(get(storyData, 'conflict.data.description', ''))
    };

    return {
      character,
      scenario,
      conflict
    };
  }

  async track(event: string, data?: any): Promise<void> {
    await app.analytics().logEvent(event, data);
  }

  async trackUserProperties(data: any): Promise<void> {
    await app.analytics().setUserProperties(data);
  }

  async setUserId(id: string): Promise<void> {
    await app.analytics().setUserId(id);
  }

  async setCurrentScreen(screenName: string): Promise<void> {
    await app.analytics().setCurrentScreen(screenName);
  }
}
