import React from 'react';
import { IonIcon } from '@ionic/react';
import { lockOpen, lockClosed } from 'ionicons/icons';

export interface ILockIconProps extends React.HTMLAttributes<HTMLElement> {
  locked: boolean;
}

export default function LockIcon (props: ILockIconProps) {
  let icon = lockOpen;
  let className = 'unlocked';

  if (props.locked) {
    icon = lockClosed;
    className = 'locked';
  }

  return (
    <IonIcon
      {...props}
      icon={icon}
      className={props.className ? `${props.className} ${className}` : className}
    ></IonIcon>
  );
}