import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButton, IonButtons, IonBackButton } from '@ionic/react';
import React, { useEffect } from 'react';
import Resource from '../scripts/Resource';

import './About.scss';
import Data from '../scripts/Data';

const { version } = require('../../package.json');

const About: React.FC = () => {
  useEffect(() => {
    Data.getInstance().analyticsProvider.setCurrentScreen('About Page');
  }, []);

  return (
    <IonPage>
      <IonHeader className="header-no-shadow">
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/" />
          </IonButtons>
          <IonTitle>{Resource.msg('about.title')}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="about-page ion-padding content-center">
        <div className="responsive-page">
          <img src="/assets/icon/icon.png" alt="Icon" className="app-logo" />
          <p>{version}</p>
          <p>{Resource.msg('about.text')}</p>

          <div className="feedback">
            <h3>{Resource.msg('feedback.title')}</h3>
            <p>{Resource.msg('feedback.text')}</p>
            <IonButton color="secondary" href={Resource.msg('feedback.url')} target="_blank">{Resource.msg('feedback.leave')}</IonButton>
          </div>

          <div className="about-developer">
            <a href={Resource.msg('about.companyLink')} target="_blank" rel="noopener noreferrer">
              <img src="/assets/logo-company.png" alt="" className="company-logo"/>
            </a>
            <p>
              {Resource.msg('about.developper')}
              <br />
              {Resource.msg('about.location')}
            </p>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default About;
