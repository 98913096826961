import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButton, IonGrid, IonRow, IonButtons, IonRouterLink, IonIcon } from '@ionic/react';
import React, { useEffect } from 'react';
import { informationCircleOutline } from 'ionicons/icons';

import './Home.scss';
import Resource from '../scripts/Resource';
import Preferences from '../scripts/Preferences';
import Marketing from '../scripts/Marketing';
import Data from '../scripts/Data';

const Home: React.FC = () => {
  useEffect(() => {
    Data.getInstance().analyticsProvider.setCurrentScreen('Generator Page');
  }, []);

  const onClickGoPro = () => {
    Data.getInstance().analyticsProvider.track('click-pro-banner');
    Marketing.goToLandingPage();
  }

  return (
    <IonPage>
      <IonHeader class="header-no-shadow">
        <IonToolbar>
          <IonTitle>{Resource.msg('generator.title')}</IonTitle>
          <IonButtons slot="start">
            <img src="/assets/icon/icon.png" alt="Icon" className="app-icon-toolbar" />
          </IonButtons>
          <IonButtons slot="end">
              <IonRouterLink routerLink="/about">
                <IonButton><IonIcon icon={informationCircleOutline} /></IonButton>
              </IonRouterLink>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding page-generator">
        <IonGrid className="full-height page-generator-grid">
          <IonRow>
            <div className="responsive-page">
              {!Preferences.isProVersion() && (
                <div className="marketing-message shadow-soft" onClick={onClickGoPro}>
                  {Resource.msg('marketing.promo1')}
                </div>
              )}
            </div>
          </IonRow>
          <IonRow className="content-full-center centered-content-wrapper">
            <div className="text-center">
              <IonRouterLink routerLink="/story">
                <IonButton color="primary" type="button" shape="round" className="button-generate-story">
                  <img src="/assets/dice.svg" alt="Reload" className="icon-reload" />
                </IonButton>
              </IonRouterLink>
              <p>{Resource.msg('generator.create')}</p>
              {Preferences.get('story.enablePrevious') && (
                <>
                  <br />
                  <IonRouterLink className="button-past-stories">Past stories</IonRouterLink>
                </>
              )}
            </div>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Home;
