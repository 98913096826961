import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButtons, IonBackButton, IonButton, IonInput, IonIcon, IonFab, IonFabButton } from '@ionic/react';
// @ts-ignore
import * as uuid from 'uuid/v4';
import { save, share } from 'ionicons/icons';
import { get as getProp } from 'dot-prop';
import React from 'react';
import Data from '../scripts/Data';
import Resource from '../scripts/Resource';

import './Story.scss';
import Preferences from '../scripts/Preferences';
import { IUserStory, ITranslatableItem } from '../Types';
import LockIcon from '../components/LockIcon';

interface IStoryComponentState {
  userStory: any,
  error: any,
  blockedConflict?: ITranslatableItem,
  blockedCharacter?: ITranslatableItem,
  blockedScenario?: ITranslatableItem
}
export default class Story extends React.Component<any, IStoryComponentState> {
  state: IStoryComponentState = {
    userStory: null as any,
    error: false,
  }

  async componentDidMount() {
    await this.setRandomStory();

    // Set screen name
    Data.getInstance().analyticsProvider.setCurrentScreen('Story Page');
  }

  async setRandomStory() {
    try {
      const randomStory = await Data.getInstance().dataProvider.getRandomStory();

      // Set the blocked items if they exist
      if (this.state.blockedConflict) {
        randomStory.conflict = this.state.blockedConflict;
      }

      if (this.state.blockedCharacter) {
        randomStory.character = this.state.blockedCharacter;
      }

      if (this.state.blockedScenario) {
        randomStory.scenario = this.state.blockedScenario;
      }

      // Create the user story
      const userStory: IUserStory = {
        id: uuid(),
        title: Resource.msg('story.defaultTitle'),
        story: randomStory
      };

      // Track the data with the analytics provider
      Data.getInstance().analyticsProvider.track('random-story', {
        blockedConflict: this.state.blockedConflict,
        blockedCharacter: this.state.blockedCharacter,
        blockedScenario: this.state.blockedScenario,
      });

      this.setState({ userStory });
    } catch (e) {
      console.log('Got error', e);
      this.setState({ error: e });
      alert(Resource.msg('story.loadError'));
    }
  }

  async saveStory() {
    try {
      await Data.saveStory(this.state.userStory);
      alert(Resource.msg('story.saveSuccesful'));
    } catch(e) {
      console.log('Error', e)
      alert(Resource.msg('story.saveError'));
    }
  }

  onChangeTitle(e: any) {
    const userStory = this.state.userStory;
    userStory.title = e.target.value;
    this.setState({ userStory })
  }

  toggleBlockedItem(stateKey: string, item: ITranslatableItem) {
    return () => {
      const state = this.state;

      // @ts-ignore
      if (state[stateKey]) {
        // @ts-ignore
        state[stateKey] = null;
      } else {
        // @ts-ignore
        state[stateKey] = item;
      }

      this.setState(state);
    }
  }

  render() {
    const userStory: IUserStory = this.state.userStory;

    return (
      <IonPage>
        <IonHeader className="header-no-shadow">
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton defaultHref="/" />
            </IonButtons>
            <IonTitle>{Resource.msg('story.title')}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding story-page">
          <div className="responsive-page">
            {!userStory && <p>{Resource.msg('app.loading')}</p>}
            {getProp(userStory, 'story.conflict') &&
            <>
              <IonInput type="text" value={userStory.title} class="pencil-edit input-story-name" onIonChange={this.onChangeTitle.bind(this)}></IonInput>

              <h2>
                <LockIcon
                  locked={!!this.state.blockedConflict}
                  className="lock-icon"
                  onClick={this.toggleBlockedItem('blockedConflict', userStory.story.conflict)}
                />
                {Resource.msg('story.conflict')}
              </h2>
              <p>{Resource.msgL(userStory, 'story.conflict.description')}</p>

              <h2>
                <LockIcon
                  locked={!!this.state.blockedCharacter}
                  className="lock-icon"
                  onClick={this.toggleBlockedItem('blockedCharacter', userStory.story.character)}
                />
                {Resource.msg('story.character')}
              </h2>
              <p>{Resource.msgL(userStory, 'story.character.name')}</p>

              <h2>
                <LockIcon
                  locked={!!this.state.blockedScenario}
                  className="lock-icon"
                  onClick={this.toggleBlockedItem('blockedScenario', userStory.story.scenario)}
                />
                {Resource.msg('story.scenario')}
              </h2>
              <p>{Resource.msgL(userStory, 'story.scenario.name')}</p>

              <IonButtons>
                {Preferences.get('story.enableSave') && <IonButton color="success" fill="solid" onClick={this.saveStory.bind(this)}><IonIcon icon={save} slot="start" /> {Resource.msg('common.save')}</IonButton>}
                {Preferences.get('story.enableShare') && <IonButton color="secondary" fill="solid"><IonIcon icon={share} slot="start" /> {Resource.msg('common.share')}</IonButton>}
              </IonButtons>
            </>
            }

            {Preferences.get('story.enableRandomize') &&
              <IonFab vertical="bottom" horizontal="end" slot="fixed">
                <IonFabButton onClick={this.setRandomStory.bind(this)}>
                  <img src="/assets/dice.svg" alt="Reload" className="icon-reload" />
                </IonFabButton>
              </IonFab>
            }
          </div>
        </IonContent>
      </IonPage>
    );
  }
}
