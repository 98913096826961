import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonSelect, IonSelectOption, IonLabel, IonList } from '@ionic/react';
import React, { useState, useEffect } from 'react';

import Resource from '../scripts/Resource';
import Preferences from '../scripts/Preferences';
import AppConfig from '../scripts/Config';

import './Config.scss';
import Marketing from '../scripts/Marketing';
import Data from '../scripts/Data';

const Config: React.FC = () => {
  const [currentLocale, setUILocale] = useState(AppConfig.getCurrentLocale());

  useEffect(() => {
    Data.getInstance().analyticsProvider.setCurrentScreen('Config Page');
  }, []);

  const onChange = (e: any) => {
    const newLocale = e.detail.value;
    AppConfig.useLocale(newLocale);
    setUILocale(e);

    // Track locale change
    Data.getInstance().analyticsProvider.track('change-locale', { locale: newLocale });
    Data.getInstance().analyticsProvider.trackUserProperties({ locale: newLocale });
  };

  const onClickGoPro = () => {
    Data.getInstance().analyticsProvider.track('click-pro-banner');
    Marketing.goToLandingPage();
  }

  return (
    <IonPage>
      <IonHeader class="header-no-shadow">
        <IonToolbar>
          <IonTitle>{Resource.msg('config.title')}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding page-config">
        <div className="responsive-page">
          {Preferences.isProVersion() ?
            <div className="pro-status enabled shadow-soft">
              {Resource.msg('marketing.versionPro')}
            </div> :
            <div className="pro-status shadow-soft" onClick={onClickGoPro}>
              {Resource.msg('marketing.versionLite')}
            </div>
          }
          <IonList>
            <div>
              <IonLabel>{Resource.msg('config.language')}</IonLabel>
              <IonSelect
                placeholder={currentLocale}
                interface="popover"
                onIonChange={onChange}
              >
                {Preferences.getLocales().map((locale) => (
                  <IonSelectOption
                    value={locale.id}
                    key={locale.id}
                    id={`lang-${locale.id}`}
                    defaultChecked={currentLocale === locale.id}
                  >
                    {locale.name}
                  </IonSelectOption>
                ))}
              </IonSelect>
            </div>
          </IonList>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Config;
